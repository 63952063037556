import "./App.scss";
import { useEffect, useMemo, useState } from "react";
import * as anchor from "@project-serum/anchor";
import Home from "./Home";
import { DEFAULT_TIMEOUT } from "./connection";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

import { createTheme, ThemeProvider } from "@material-ui/core";
import { ViewState } from "./utils";
import Layout from "./Layout";
import { NFTList } from "./config";
import { CrossMintPay } from "./CrossMintPay";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

// const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
//   try {
//     return new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);
//   } catch (e) {
//     console.log("Failed to construct CandyMachineId", e);
//     return undefined;
//   }
// };

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const getCandyMachinKey = (id: string): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(id);
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

// const candyMachineId = getCandyMachineId();
const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  "devnet") as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl("devnet");
const connection = new anchor.web3.Connection(rpcHost);

// const NFTList = [
//   {
//     id: 1,
//     img: '/assets/nfts/pin-01.png',
//     candyMachineId: 'GY6RhRhgZVrvCWSxEHkiP2Ys3rqd3t5awa6s286E83Jb',
//     crossMintId: '7607dc99-3976-47e7-956a-593e5a3155c7'
//   },
//   {
//     id: 2,
//     img: '/assets/nfts/pin-02.png',
//     candyMachineId: '4nj4uUdH8fAHLRFh1EZjipqmSF4VK3dwgwarAf2n5dNq',
//     crossMintId: '7607dc99-3976-47e7-956a-593e5a3155c7'
//   },
//   {
//     id: 3,
//     img: '/assets/nfts/pin-03.png',
//     candyMachineId: '2L4cZrx29Z65BbZ6wLRYyuSaiFnmbeWXTcBpk7y2EfBP',
//     crossMintId: '7607dc99-3976-47e7-956a-593e5a3155c7'
//   },
//   {
//     id: 4,
//     img: '/assets/nfts/pin-04.png',
//     candyMachineId: '487eaxK6b6XS2Tbph7LWk11J66zvwx32ZPTzWuCmsSJA',
//     crossMintId: '7607dc99-3976-47e7-956a-593e5a3155c7'
//   },
//   {
//     id: 5,
//     img: '/assets/nfts/pin-05.png',
//     candyMachineId: '487eaxK6b6XS2Tbph7LWk11J66zvwx32ZPTzWuCmsSJA',
//     crossMintId: '7607dc99-3976-47e7-956a-593e5a3155c7'
//   },
//   {
//     id: 6,
//     img: '/assets/nfts/pin-06.png',
//     candyMachineId: '487eaxK6b6XS2Tbph7LWk11J66zvwx32ZPTzWuCmsSJA',
//     crossMintId: '7607dc99-3976-47e7-956a-593e5a3155c7'
//   },
//   {
//     id: 7,
//     img: '/assets/nfts/pin-07.png',
//     candyMachineId: '487eaxK6b6XS2Tbph7LWk11J66zvwx32ZPTzWuCmsSJA',
//     crossMintId: '7607dc99-3976-47e7-956a-593e5a3155c7'
//   }
// ]

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const [viewState, setViewState] = useState(ViewState.ALL);
  const [selectedNFTIndex, setSelectedNFTIndex] = useState<String | null>(null);
  const selectedNFT = NFTList.find(obj => obj.id === selectedNFTIndex);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );

  useEffect(() => {
    const url = new URL(window.location.toString());
    const path = url.pathname.replace("/", "");

    const hasSelectedNFT = NFTList.find(nft => nft.id === path);

    if(hasSelectedNFT){
      setSelectedNFTIndex(hasSelectedNFT.id);
      setViewState(ViewState.SINGLE);
    }
  }, []);

  const onSelectNFT = (nftId: string) => {
    setSelectedNFTIndex(nftId);
    setViewState(ViewState.SINGLE);
    
    navigate(nftId, "/" + nftId);
  }

  const onSelectBack = () => {
    setSelectedNFTIndex(null);
    setViewState(ViewState.ALL);
    navigate("Roundtable Mint", "/");
  }

  const navigate = (title: string, url: string) => {
    if(window.history && window.history.replaceState){
      window.history.replaceState(null, title, url);
    }else{
      window.location.pathname = url;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
          <Layout
              selectedNFT={selectedNFT}
              NFTList={NFTList}
              viewState={viewState}
              onSelectNFT={onSelectNFT}
              onSelectBack={onSelectBack}
            >
            {selectedNFT && 
              <>
                <Home
                  candyMachineId={getCandyMachinKey(selectedNFT?.candyMachineId)}
                  connection={connection}
                  txTimeout={DEFAULT_TIMEOUT}
                  rpcHost={rpcHost}
                  network={network}
                  error={error}
                />  
                <CrossMintPay 
                  clientId={selectedNFT.crossMintId}
                />
              </>
            }
          </Layout>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;
