export const NFTList = [
    {
      id: "CRT1",
      img: '/assets/nfts/pin-01.png',
      candyMachineId: 'CK6pbw6N7EEkMRXtZgCdkJe6pJQ3EYK5zGKMk33U3jRa',
      crossMintId: 'cc74c49d-99e7-490a-bf12-44deb904036c',
      price: 0.27
    },
    {
      id: "CTRT8",
      img: '/assets/nfts/pin-02.png',
      candyMachineId: '3pD5GCTvQiHWgyzXgYUpJ1Q654gXXVQQnHpsPA6RMvvS',
      crossMintId: 'da482ed2-9b99-4ff3-b63e-4694a81f427e',
      price: 0.27
    },
    {
      id: "RTSL",
      img: '/assets/nfts/pin-03.png',
      candyMachineId: '9ey81q9PPxBYCr9aWL8oeM2rCvsi45pjgRVJHQtQPMoP',
      crossMintId: '9dd9ea8a-0113-43ee-9a22-0fda59e8832e',
      price: 1.37
    },
    {
      id: "RTSL-Ranil",
      img: '/assets/nfts/pin-04.png',
      candyMachineId: 'DaXapioSMikDsq1MsydcQXC22DawCnBjo5AppCp2Hj5M',
      crossMintId: '884ced84-b605-4448-945e-98073182bc9f',
      price: 0.27
    },
    {
      id: "NRT7",
      img: '/assets/nfts/pin-05.png',
      candyMachineId: 'AK1SmdLjWpfoj88RbMReXKeibwQYmfgi3j8j5uDqgaxe',
      crossMintId: '6b02190c-bdf8-4cbf-8b17-ac201df9d5f5',
      price: 0.27
    },
    {
      id: "CTRT3",
      img: '/assets/nfts/pin-06.png',
      candyMachineId: '6waPciTEW2WyLZxzWPbXxxiVaVRTUnXbkkh6ig1rp46p',
      crossMintId: '91c7d11f-3517-4d26-a21a-e6bb5ef025a7',
      price: 0.27
    },
    {
      id: "CCRT4",
      img: '/assets/nfts/pin-07.png',
      candyMachineId: 'E6dBRdhUEuDssBDCJGfK8L7X6psUZSkkcQK31PDHKjfx',
      crossMintId: '250250c2-ddc0-4a64-8749-e7e6040e18bc',
      price: 0.27
    }
]