/* eslint-disable react/prop-types */

import React from 'react';
import { ViewState } from './utils';

export default function Layout({ children, NFTList, viewState, selectedNFT, onSelectNFT, onSelectBack }){
    return (
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
            <header className="masthead">
                <div className="inner d-block d-md-flex">
                    <img src="/assets/roundtable-logo.png" className="logo" />
                    <nav className="nav nav-masthead justify-content-center mt-2 ms-md-4">
                        <a className="nav-link active" href="#">HOME</a>
                        <a className="nav-link" href="https://roundtable.hyperglade.com/faqs/ ">FAQ</a>
                        <a className="nav-link" target="_blank" href="blog.hyperglade.com ">NFT GUIDE</a>
                    </nav>
                </div>
            </header>

            <main role="main" className="content"> 
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 p-4 d-flex justify-content-md-end">
                        <div className="nft-card p-4 d-flex flex-column rounded-3 h-100">
                            <img className="align-self-center" src={selectedNFT ? selectedNFT.img : '/assets/pin.png'} width="50%" />
                            <h3 className='mt-2 mb-2 text-left'>
                                Buy your Roundtable NFT Pin now!
                            </h3>
                            <p>
                            Experience world popular Round Table Pins, as Digital collectibles with real world utility
                                <ol>
                                    <li>Select your NFT pin</li>
                                    <li>Select your preferred method of payment. (Connect wallet for Solana, Crossmint for Credit or debit card payments)</li>
                                    <li>Receive NFT to your wallet.</li>
                                </ol>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-4">
                        <div className="nft-card rounded-3 p-4 h-100">
                            <div className="p-4 d-flex flex-column w-100">
                                <h3 className='mb-0 d-flex'>
                                    Roundtable NFT Pins

                                    {viewState === ViewState.SINGLE &&
                                        <div className='ms-auto'>
                                            <a className='btn btn-dark ms-auto' onClick={onSelectBack}>Change</a>
                                        </div>
                                    }
                                </h3>
                                <hr />

                                {viewState === ViewState.ALL && 
                                    <div className="w-100 d-flex pins">
                                        {NFTList.map((nft, index) => 
                                            <img className='pin' src={nft.img} key={index} onClick={() => onSelectNFT(nft.id)} />
                                        )}
                                    </div>
                                }

                                {viewState === ViewState.SINGLE && 
                                    <>
                                        <div className="w-100">
                                            <span className="w-100" style={{ fontSize: "0.8em" }}>Collection</span>
                                            <div className="d-flex w-100 align-items-center mt-2 w-100">
                                                <img src="/assets/roudtable-logo-small.png" width="50px" height="45px" />
                                                <div className="ms-2">
                                                    <div style={{ fontSize: "1.2em" }}>
                                                        <b>Roundtable NFT Pins</b>
                                                    </div>
                                                    <div style={{ fontSize: "0.8em" }}>
                                                        First set of Roundtable pins on blockchain.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className="d-flex justify-content-between w-100">
                                            <div className="text-left">
                                                <p style={{ fontSize: "0.8em" }}>
                                                    Quantity:<br />
                                                    Cost:<br />
                                                    Fees:
                                                </p>
                                                <h4>Total</h4>
                                            </div>
                                            <div className="text-right">
                                                <p style={{ fontSize: "0.8em", textAlign: 'right' }}>
                                                    x1<br />
                                                    {selectedNFT?.price} SOL<br />
                                                    Gas Fees Apply
                                                </p>
                                                <h4 align='right'>{selectedNFT?.price} SOL</h4>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className="w-100">
                                            {children}
                                        </div>
                                    </>
                                }



                                

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    ) 
}
