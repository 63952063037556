/* eslint-disable react/prop-types */

import React from 'react';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

export function CrossMintPay({ clientId }) {
    return (
        <div className="w-100 d-flex justify-content-center">
            <CrossmintPayButton
                collectionTitle="Roundtable"
                collectionDescription="Roundtable Pin mint."
                collectionPhoto="https://mint.roundtable.hyperglade.com/assets/pin.png"
                clientId={clientId}
                
                mintConfig={{"type":"candy-machine"}}
            />
        </div>
    );
}